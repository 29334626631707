const faqData = [
  {
    summary: "How to Book a Ethio Smart Ride Transportation?",
    data: "Learn how to effortlessly book a ride through our platform. Discover the simple steps involved in scheduling your transportation, whether you're heading to work, the airport, or a special event.",
    name: "panel1",
  },
  {
    summary: "What Payment Methods Are Accepted?",
    data: "Find out the various payment methods we accept for your convenience. Whether you prefer credit/debit cards, mobile wallets, or cash, we've got you covered with secure and hassle-free transactions.",
    name: "panel2",
  },
  {
    summary: "Can I Schedule a Ethio Smart Ride Transportation in Advance?",
    data: "Explore the option to schedule rides in advance for added convenience. Plan your trips ahead of time and enjoy the peace of mind knowing your transportation needs are taken care of.",
    name: "panel3",
  },
  {
    summary: "Is there a Cancellation Policy?",
    data: "Familiarize yourself with our cancellation policy to understand any applicable charges or guidelines. We strive to provide flexibility while ensuring fairness to both riders and drivers.",
    name: "panel4",
  },
  {
    summary: "Are the Drivers Background Checked?",
    data: "Peer-to-peer (P2P) lending is a financial model that allows individuals to lend money directly to other individuals. In a P2P lending system, borrowers apply for loans on online platforms, and investors can choose to fund those loans in exchange for a return on their investment. P2P lending can offer borrowers access to lower interest rates and fees compared to traditional lenders, while investors can earn higher returns on their investments than they would in savings accounts or other low-risk investments.",
    name: "panel5",
  },
  {
    summary: "Can I Contact Customer Support?",
    data: "Need assistance or have a question? Our dedicated customer support team is here to help. Learn how to get in touch with us via phone, email, or live chat for prompt assistance with your ride booking inquiries.",
    name: "panel6",
  },
  {
    summary: "Are the Drivers Background Checked?",
    data: "Absolutely. Safety is our utmost priority at Ethio Smart Ride Transportation. All our drivers undergo rigorous background checks before joining our platform. We verify their driving history, criminal record, and conduct thorough screenings to ensure that you ride with peace of mind. With Ethio Smart Ride Transportation, you can trust that your driver meets our high standards of professionalism and safety.",
    name: "panel7",
  },
];
export default faqData;

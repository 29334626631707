// // Check if the location is in Ethiopia
// export const checkServiceLocation = (addressComponents) => {
//   var country = addressComponents.find((component) =>
//     component.types.includes("country")
//   )?.short_name;

//   // Change "US" to "ET" for Ethiopia
//   return country === "ET";
// };

// // Check if the location is in Addis Ababa, Ethiopia
// export const isLocationInAddisAbaba = (addressComponents) => {
//   var country = addressComponents.find((component) =>
//     component.types.includes("country")
//   )?.short_name;

//   // Check for administrative area or locality being Addis Ababa
//   var administrativeArea = addressComponents.find((component) =>
//     component.types.includes("administrative_area_level_1")
//   )?.short_name;

//   var locality = addressComponents.find((component) =>
//     component.types.includes("locality")
//   )?.short_name;

//   // Change "US" and "CA" to "ET" for Ethiopia and Addis Ababa as the city
//   return country === "ET" && (administrativeArea === "Addis Ababa" || locality === "Addis Ababa");
// };
// #################################################################################################
// export const checkServiceLocation = (addressComponents) => {
//   var country = addressComponents.find((component) =>
//     component.types.includes("country")
//   )?.short_name;
//   return country === "US";
// };

// export const isLocationInColifornia = (addressComponents) => {
//   var country = addressComponents.find((component) =>
//     component.types.includes("country")
//   )?.short_name;
//   var administrativeArea = addressComponents.find((component) =>
//     component.types.includes("administrative_area_level_1")
//   )?.short_name;
//   return country === "US" && administrativeArea === "CA";
// };




// ############################################################################################

// Check if the location is in Ethiopia
export const checkServiceLocation = (addressComponents) => {
  var country = addressComponents.find((component) =>
    component.types.includes("country")
  )?.short_name;

  // Check if the country is Ethiopia (ET)
  return country === "ET";
};

// Check if the location is in any administrative area or locality within Ethiopia
export const isLocationInEthiopia = (addressComponents) => {
  var country = addressComponents.find((component) =>
    component.types.includes("country")
  )?.short_name;

  // Get the administrative area or locality
  var administrativeArea = addressComponents.find((component) =>
    component.types.includes("administrative_area_level_1")
  )?.short_name;

  var locality = addressComponents.find((component) =>
    component.types.includes("locality")
  )?.short_name;

  // Return true if the country is Ethiopia and either administrativeArea or locality is defined
  return country === "ET" && (administrativeArea || locality);
};
